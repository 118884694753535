/* eslint-disable react/function-component-definition */
/* eslint-disable react/forbid-prop-types */
import React from 'react'
import { Link } from 'gatsby'
import Plaatjie from '@ubo/plaatjie'
import styled from 'styled-components'
import parse from 'html-react-parser'

import StreamModal from '../StreamModal'

import BoxDefault from './BoxDefault'

const Card = styled.div`
  line-height: 1.2;
  position: relative;

  p {
    margin-bottom: 0;
  }

  a {
    font-size: ${(props) => props.theme.font.size.m};
    &:hover {
      text-decoration: underline;
      color: ${(props) => props.theme.color.text.contrast};
    }
  }

  &.with-offset {
    padding-right: 7.5%;

    .box {
      margin-top: -25px;
      margin-left: 7.5%;
    }
  }

  @media (max-width: 1199.98px) {
  }

  @media (max-width: 991.98px) {
    margin-bottom: 35px;
  }

  @media (max-width: 767.98px) {
  }

  @media (max-width: 575.98px) {
  }
`

// const StreamImage = styled.img`
//   width: 200px;
//   position: absolute;
//   top: 0;
//   z-index: 2;
// `

const Title = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  height: 100px;
`

const Image = styled(Plaatjie)`
  height: 351px;
  width: 100%;

  @media (max-width: 991px) {
    height: 215px;
  }
`

const BlogCard = ({ src, title, link, livestream, location, offset }) => {
  let linkType
  if (link.href) {
    linkType = <a href={link.href}>{link.title}</a>
  } else {
    linkType = <Link to={link.to}>{link.title}</Link>
  }

  return (
    <Card className={`d-flex flex-column ${offset ? 'with-offset' : ''}`}>
      {livestream && livestream.iframe ? (
        <StreamModal image={livestream.image} link={livestream} />
      ) : (
        <p />
      )}
      <Image image={src} />
      <BoxDefault className="box" big>
        <Title className="text-uppercase font-family-secondary font-size-ll text-white font-weight-500">
          {parse(title)}{' '}
          {location !== null && (
            <>
              - <span className="font-weight-200">{parse(location)}</span>
            </>
          )}
        </Title>
        <div className="d-flex flex-wrap align-items-center justify-content-between pr-3">
          {livestream && livestream.iframe ? (
            <StreamModal content="Bekijk livestream >" link={livestream} />
          ) : (
            <p />
          )}
          {linkType}
        </div>
      </BoxDefault>
    </Card>
  )
}

export default BlogCard
