/* eslint-disable react/function-component-definition */
import React, { useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'

// Elements
import Pagination from 'react-js-pagination'

// Third Party
import styled from 'styled-components'
import BlogCard from './BlogCard'

const StyledBlogOverview = styled.div``

const Title = styled.h2`
  font-family: ${(props) => props.theme.font.family.secondary};
`

const PaginationWrapper = styled.div`
  ul {
    margin-bottom: 0;
  }

  li {
    margin-right: 15px;
    position: relative;
    font-size: 20px;
    font-weight: 300;
    background-color: transparent;
    color: ${(props) => props.theme.color.text.contrast};
    border: 2px solid ${(props) => props.theme.color.contrast};
    padding: 6px 5px;
    padding-right: 6px;
    line-height: 1;

    &.active {
      a {
        color: #fff;
      }
      background-color: ${(props) => props.theme.color.contrast};
    }

    a {
      text-align: center;
      min-width: 20px;
      display: block;
      text-decoration: none;
    }

    &::after {
      content: '';
      display: block;
      width: 20px;
      height: 21px;
      position: absolute;
      right: -10px;
      bottom: -11px;

      transform: rotate(45deg);
      background: #fff;
      border-left: 2px solid ${(props) => props.theme.color.contrast};
    }
  }
`

const BlogOverview = ({ className }) => {
  const {
    posts: { nodes: posts },
  } = useStaticQuery(graphql`
    {
      posts: allWpPost(sort: { fields: date, order: DESC }) {
        nodes {
          databaseId
          title
          uri
          postRecap {
            recapPreview {
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 1920, quality: 100)
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const [page, setPage] = useState(1)

  const setLocalPage = (edge) => {
    if (typeof localStorage !== 'undefined') {
      localStorage.setItem('project-page-number', edge)
    }

    setPage(edge)
  }

  const maxPageItems = 6

  function paginate(items) {
    let offset = 0
    if (page !== 1) {
      offset = (page - 1) * maxPageItems
    }

    return items.slice(offset, offset + maxPageItems)
  }

  const visibilePosts = paginate(posts)

  return (
    <StyledBlogOverview
      className={`container py-lg-5 py-3 ${className ? `${className}` : ``}`}
    >
      <Title>BLOG</Title>
      <div className="row">
        {visibilePosts.map((p) => (
          <div key={p.databaseId} className="col-md-6 mb-2 mb-lg-4">
            <BlogCard
              src={p.postRecap.recapPreview.image}
              title={p.title}
              link={{ to: p.uri, title: 'Bekijk bericht >' }}
              location={null}
            />
          </div>
        ))}
      </div>

      <div className="row">
        {posts.length > maxPageItems && (
          <PaginationWrapper className="col-lg-12 d-flex justify-content-end align-items-center">
            <span className="mr-4">
              Pagina {page} van de {Math.ceil(posts.length / maxPageItems)}
            </span>
            <Pagination
              activePage={page}
              itemsCountPerPage={maxPageItems}
              totalItemsCount={posts.length}
              pageRangeDisplayed={5}
              itemClass="paginate-item"
              onChange={setLocalPage}
              hideNavigation
              hideFirstLastPages
            />
          </PaginationWrapper>
        )}
      </div>
    </StyledBlogOverview>
  )
}

export default BlogOverview
