/* eslint-disable react/function-component-definition */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'

// Components
import SEO from 'components/SEO'
import Layout from 'components/shared/Layout'
import BlogOverview from 'components/elements/BlogOverview'
import Hero from '../components/elements/Hero'

export const pageQuery = graphql`
  query ($id: String) {
    page: wpPage(id: { eq: $id }) {
      title
      uri
      content
      pageBlog {
        banner {
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 1920, quality: 100)
                meta: fluid(maxWidth: 800, toFormat: JPG) {
                  src
                }
              }
            }
          }
        }
      }
      seo {
        canonical
        cornerstone
        focuskw
        metaDesc
        metaKeywords
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        opengraphModifiedTime
        opengraphPublisher
        opengraphPublishedTime
        opengraphSiteName
        opengraphTitle
        opengraphType
        opengraphUrl
        title
        twitterDescription
        twitterTitle
        opengraphImage {
          sourceUrl
        }
        twitterImage {
          sourceUrl
        }
      }
    }
  }
`

const PageTemplate = ({
  data: {
    page: { pageBlog, seo },
  },
}) => (
  <Layout subFooter>
    <SEO seo={seo} />
    <Hero title="BLOG" src={pageBlog.banner.image} />

    <BlogOverview />
  </Layout>
)

export default PageTemplate
